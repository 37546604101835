import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import type { Rule } from 'rc-field-form/es/interface'

const today = dayjs().hour(0)

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const dateValidators = {
  isFutureDay: (date: Dayjs) => today.isBefore(date, 'day'),
  isFutureDayOrToday: (date: Dayjs) => today.isSameOrBefore(date, 'day'),
  isFutureMonth: (date: Dayjs) => today.isBefore(date, 'month'),
  isPastDay: (date: Dayjs) => today.isAfter(date, 'day'),
  isPastDayOrToday: (date: Dayjs) => today.isSameOrAfter(date, 'day'),
}

export const checkDateInPastOrTodayRule: (message: string) => Rule = (message: string) => ({
  message,
  type: 'date',
  validator: (_, value: Dayjs) => {
    return !value || dateValidators.isPastDayOrToday(value) ? Promise.resolve() : Promise.reject()
  },
})

export const checkDateInFutureRule: (message: string) => Rule = (message: string) => ({
  message,
  type: 'date',
  validator: (_, value: Dayjs) => {
    return !value || dateValidators.isFutureDay(value) ? Promise.resolve() : Promise.reject()
  },
})
