import type { CSSProperties, FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ITrain } from '@Types/routes/train'
import Label, { LabelWrapper } from '@base/label/Label'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import RouteInfoTime from '@widgets/checkout/timetable/routeData/trainsList/components/routeInfo/routeInfoTime/RouteInfoTime'
import RouteInfoStations from '@widgets/checkout/timetable/routeData/trainsList/components/routeInfo/routeInfoTime/routeInfoStations/RouteInfoStations'

import choseFirstClassOnRowClick from '../../utils/choseFirstClassOnRowClick'

type Props = {
  cheapestTrain: boolean
  isOnlyRecommendedFilter: boolean
  isShowRecommendedStationBadge?: boolean
  isSmall?: boolean
  onClick?: () => void
  styles?: CSSProperties
  train: ITrain
}

const RouteInfo: FC<Props> = ({
  isOnlyRecommendedFilter,
  isShowRecommendedStationBadge,
  isSmall,
  onClick,
  styles,
  train,
}) => {
  const { t } = useTranslation('Timetable')
  const isMobile = useMediaQuery(mediaQueries.mobile)

  return (
    <RouteInfoWrapper onClick={onClick || choseFirstClassOnRowClick} style={styles}>
      {isShowRecommendedStationBadge && train?.is_recommended_train_flg && !isOnlyRecommendedFilter && (
        <Label size="s">{t('Recommended')}</Label>
      )}
      <RouteInfoTime isSmall={!isMobile && isSmall} train={train} />
      <RouteInfoStations
        arrivalStation={train?.arrival_station}
        changeStation={train?.change_station}
        departureStation={train?.departure_station}
      />
    </RouteInfoWrapper>
  )
}

export const RouteInfoWrapper = styled.div`
  position: relative;
  padding: 25px 32px;
  min-width: 420px;

  & > ${LabelWrapper} {
    position: absolute;
    writing-mode: vertical-lr;
    transform: rotate(180deg) translate(50%, 50%);
    top: 50%;
    left: 0;
    padding: 6px 4px;
    min-width: 24px;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    min-width: 360px;
    padding: 24px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    min-width: 100%;
    padding: 16px 24px 0 24px;

    & > ${LabelWrapper} {
      position: relative;
      transform: none;
      top: 0;
      writing-mode: initial;
      margin-bottom: 8px;
      padding: 2px 8px;
    }
  }
`

export default memo(RouteInfo)
