import styled from 'styled-components'

import TextButton from '@base/buttons/TextButton'
import { captionMediumStyles } from '@styles/typography/caption'
import { ellipsisText } from '@styles/typography/textStyles'
import { title7Styles, title9Styles } from '@styles/typography/title'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  padding: 16px;
  width: calc(100% - 32px);
  border: 1px dashed ${p => p.theme.colors.buttonText};
  border-radius: ${p => p.theme.borderRadius.default};

  ${p => p.theme.mediaQueries.desktopS} {
    width: calc(100%);
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-area: auto;
    border: none;
    border-bottom: 1px solid ${p => p.theme.colors.border};
    border-radius: 0;
    padding: 14px 24px;
  }
`

export const InfoViewWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;

  ${p => p.theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`

export const Title = styled.span`
  ${title7Styles};
  line-height: 24px;
  text-transform: uppercase;

  ${p => p.theme.mediaQueries.mobile} {
    ${title9Styles};
    text-transform: uppercase;
    color: ${p => p.theme.colors.primary};
  }
`

export const Station = styled.span`
  ${title7Styles};
  ${ellipsisText};
  min-width: 80px;
  line-height: 24px;

  ${p => p.theme.mediaQueries.desktopS} {
    ${title9Styles};
  }
`

export const StyledButton = styled(TextButton)`
  justify-content: flex-start;

  span {
    ${captionMediumStyles};
  }
`
