import Popover from 'antd/es/popover'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'

import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import Caption from '@typography/Caption'
import { checkOverflow } from '@utils/checkOverflow/checkOverflow'

type StationProps = {
  stationName: string
}

export const Station: FC<StationProps> = ({ stationName }) => {
  const captionRef = useRef<HTMLSpanElement>(null)
  const [isTextOverflow, setIsTextOverflow] = useState(false)
  const isMobile = useMediaQuery(mediaQueries.mobile)

  useEffect(() => {
    setIsTextOverflow(checkOverflow(captionRef.current))
  }, [])

  const station = (
    <Caption refProp={captionRef} size="s">
      {stationName}
    </Caption>
  )

  if (!isTextOverflow || isMobile) return station

  return (
    <Popover content={stationName} destroyTooltipOnHide>
      {station}
    </Popover>
  )
}
