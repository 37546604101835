import { useEffect } from 'react'

import { disableScroll, enableScroll } from '@utils/scrollControl/scrollControl'

const useScrollControl = (disabled = false) => {
  useEffect(() => {
    disabled ? disableScroll() : enableScroll()
  }, [disabled])
}

export default useScrollControl
