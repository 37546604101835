import DatePicker from 'antd/es/date-picker'
import type { RangePickerProps } from 'antd/es/date-picker'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import type { FC, MouseEvent } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { datePickerButtons } from '@base/DatePicker'
import ClearReturnDateButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/ClearReturnDateButton'
import MobileHeader from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/mobileHeader/MobileHeader'
import {
  CalenderIcon,
  DateInputGlobalStyles,
  DateLabel,
  WrapperDateInput,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/DateInput/styles'
import {
  datePikerLabelClassName,
  datePikerPopupClassName,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/classNames'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import SaveButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/saveButton/SaveButton'
import dateFormats from '@constants/dates/dateFormats'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import confirmButtonClickHandler from '@utils/datePicker/confirmButtonClickHandler'
import { dateValidators } from '@validation/rules/date'

dayjs.extend(weekday)
dayjs.extend(localeData)

type Props = {
  close: () => void
  compactLabelView?: boolean
  datePickerProps?: RangePickerProps
  isOpened: boolean
  onClearReturnDate?: () => void
  open: () => void
  placeholder?: [string, string]
  position: 'bottom' | 'right'
}

export const DateInput: FC<Props> = ({
  close,
  compactLabelView,
  datePickerProps,
  isOpened,
  onClearReturnDate,
  open,
  placeholder,
  position,
}) => {
  const [activeInput, setActiveInput] = useState<string>()
  const { t } = useTranslation('Search form')
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const isMobileTablet = useMediaQuery(mediaQueries.mobileTablet)
  const departureCaptionText = t('departureDate')
  const returnCaptionText = t('returnDate')
  const departurePlaceholder = `+${t('departure')}`
  const returnPlaceholder = `+ ${t('addReturn')}`

  const commonProps: RangePickerProps = {
    allowClear: false,
    autoFocus: true,
    clearIcon: false,
    disabledDate: dateValidators.isPastDay,
    format: dateFormats['01 Jan 2023'],
    getPopupContainer: node => node,
    inputReadOnly: true,
    minDate: dayjs(),
    needConfirm: isMobile,
    onOpenChange: value => (value ? open() : close()),
    open: isOpened,
    panelRender: panel => (
      <>
        {isMobile && <MobileHeader closeAction={close} />}
        {panel}
      </>
    ),
    placement: 'bottomLeft' as const,
    renderExtraFooter: () => (
      <SaveButton
        onClick={e => {
          confirmButtonClickHandler(e)
          close()
        }}
      />
    ),
    showNow: false,
    style: {
      height: `${isMobile ? searchFormSizes.fieldHeightMobile : searchFormSizes.fieldHeightDesktop}px`,
      padding: 0,
      width: '100%',
    },
    suffixIcon: compactLabelView ? null : <CalenderIcon />,
    variant: 'borderless',
    ...datePickerButtons,
    ...(datePickerProps || {}),
    popupStyle: {
      height: `${isMobile ? '100%' : `${formHeightDesktop + searchFormSizes.layoversHeightDesktop}px`}`,
      padding: 0,
      position: isMobile ? 'fixed' : 'absolute',
      width: `${
        isMobile
          ? '100%'
          : isMobileTablet
          ? `${searchFormSizes.popupWidthTablet}px`
          : `${searchFormSizes.popupWidthDesktop}px`
      }`,
      ...datePickerProps?.popupStyle,
    },
  }

  const popupClassName = `${datePikerPopupClassName} ${position}`

  const onClickInput = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const placeholder = (e.target as HTMLInputElement).placeholder

      if (activeInput === placeholder && isOpened) {
        close()
      } else {
        setActiveInput(placeholder)
      }
    },
    [isOpened]
  )

  return (
    <WrapperDateInput>
      <DateLabel
        $compactLabelView={compactLabelView}
        $departureLabel={departureCaptionText}
        $isPopoverOpened={isOpened}
        $returnLabel={returnCaptionText}
        className={datePikerLabelClassName}
        name={SearchFormKeys.date}
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker
          {...commonProps}
          allowEmpty={[false, true]}
          onClick={onClickInput}
          placeholder={placeholder ? placeholder : [departurePlaceholder, returnPlaceholder]}
          popupClassName={popupClassName}
          separator={null}
        />
      </DateLabel>
      {onClearReturnDate && <ClearReturnDateButton onClear={onClearReturnDate} />}
      <DateInputGlobalStyles />
    </WrapperDateInput>
  )
}
