import { analyticsApiEndpoints } from '@redux/features/analytics/analytics.api'
import { getProducts } from '@redux/features/analytics/utils/getProducts'
import { selectBookingId, selectSearchSessionId } from '@redux/features/checkout/checkout.selectors'
import { orderEndpoints } from '@redux/features/order/order.api'
import type { RootState } from '@redux/store'
import { createAsyncThunk } from '@reduxjs/toolkit'

import type {
  AddToCartAnalyticsType,
  CheckoutAnalyticsType,
  DataLayerType,
  PurchaseAnalyticsType,
} from '@Types/analytics/analytics'
import { AnalyticsEvents } from '@constants/analytics/analyticsEvents'
import { LogEvents } from '@constants/log/logEvents'
import userSettingsStorage from '@storageServices/storageEntities/userSettings'
import { getStationName } from '@utils/route/routeName'

export const pushedEvents: Set<AnalyticsEvents> = new Set()

type PurchaseProps = { bookingId: string; event: AnalyticsEvents.PURCHASE }

type ThunkProps = { event: Exclude<AnalyticsEvents, AnalyticsEvents.PURCHASE> } | PurchaseProps

export default createAsyncThunk<void, ThunkProps, { state: RootState }>(
  'analytics/pushAnalyticEvent',
  async ({ event, ...thunkProps }, { dispatch, getState }) => {
    if (!window.dataLayer || pushedEvents.has(event)) return

    const state = getState()
    const searchSessionId = selectSearchSessionId(state) || ''
    const bookingId = selectBookingId(state) || ''

    pushedEvents.add(event)

    switch (event) {
      case AnalyticsEvents.TRAIN_SELECT: {
        void dispatch(
          analyticsApiEndpoints.putLogAdditional.initiate({ id: searchSessionId, type: LogEvents.TRAIN_SELECTED })
        )
        break
      }
      case AnalyticsEvents.PAY_BTN_CLICK: {
        window.dataLayer.push({ event })
        void dispatch(analyticsApiEndpoints.putLog.initiate({ id: bookingId, type: LogEvents.PAYMENT }))
        break
      }
      case AnalyticsEvents.PASSENGER_PAGE: {
        void dispatch(analyticsApiEndpoints.putLog.initiate({ id: bookingId, type: LogEvents.PASSENGER_PAGE }))
        break
      }
      case AnalyticsEvents.DETAILS:
      case AnalyticsEvents.TIMETABLE_FAILED:
      case AnalyticsEvents.TIMETABLE_SUCCESS:
      case AnalyticsEvents.CHECKOUT:
      case AnalyticsEvents.ADD_TO_CART: {
        const result = {
          ecommerce: {
            currencyCode: userSettingsStorage.getValue()?.currency || '',
          },
          event,
        }

        const products = getProducts(state)

        if (event === AnalyticsEvents.ADD_TO_CART) {
          void dispatch(
            analyticsApiEndpoints.putLogAdditional.initiate({ id: searchSessionId, type: LogEvents.ADD_TO_CART })
          )
          ;(result as AddToCartAnalyticsType).ecommerce.add = { products }
        }

        if (event === AnalyticsEvents.CHECKOUT) {
          void dispatch(analyticsApiEndpoints.putLog.initiate({ id: bookingId, type: LogEvents.CHECKOUT }))
          ;(result as CheckoutAnalyticsType).ecommerce.checkout = { products }
        }

        window.dataLayer.push(result as DataLayerType)
        break
      }
      case AnalyticsEvents.PURCHASE: {
        const orderData = await dispatch(
          orderEndpoints.getOrder.initiate((thunkProps as PurchaseProps).bookingId)
        ).unwrap()
        const result: PurchaseAnalyticsType = {
          ecommerce: {
            currencyCode: orderData.param235.currency_code,
            purchase: {
              actionField: { id: orderData.number, revenue: orderData.param235.number },
              products: orderData.serviceOrders.map(({ arrivalStation, coachClass, departureStation, tickets }) => ({
                category: coachClass.name || undefined,
                id: coachClass.id,
                name: `${getStationName(departureStation)} - ${getStationName(arrivalStation)}`,
                quantity: tickets?.reduce((acc, ticket) => acc + ticket.passengers.length, 0),
              })),
            },
          },
          event,
        }

        pushedEvents.clear()
        window.dataLayer.push(result)
      }
    }
  }
)
