import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { LabelWrapper } from '@base/label/Label'
import useTravelTime from '@hooks/useTravelTime/useTravelTime'
import DotsIconShort from '@publicIcons/dotsGrowing3.svg'
import DotsIconLong from '@publicIcons/dotsGrowing9.svg'
import Caption from '@typography/Caption'

type Props = {
  isSmall?: boolean
  runningTime: number | string
  trainChange?: boolean
}

const RouteInfoTravelTime: FC<Props> = ({ isSmall, runningTime, trainChange }) => {
  const travelTime = useTravelTime(runningTime)
  const { t } = useTranslation('Timetable')

  return (
    <Wrapper $isSmall={isSmall}>
      <DotsIconShortStyled $isSmall={isSmall} style={{ gridArea: 'dotsShortStart' }} />
      <DotsIconLongStyled $isSmall={isSmall} style={{ gridArea: 'dotsLongStart' }} />
      <TravelTime size="xs">
        {travelTime} {trainChange && `${t('withChange')}`}
      </TravelTime>
      <DotsIconShortStyled $isSmall={isSmall} style={{ gridArea: 'dotsShortEnd', scale: '-1' }} />
      <DotsIconLongStyled $isSmall={isSmall} style={{ gridArea: 'dotsLongEnd', scale: '-1' }} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isSmall?: boolean }>`
  flex: 1;
  height: 32px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-areas: ${p =>
    p.theme.mediaQueries.tablet && !p.$isSmall
      ? "'dotsLongStart travelTime dotsLongEnd'"
      : "'dotsShortStart travelTime dotsShortEnd'"};
  grid-gap: 12px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 15px;

  svg {
    fill: ${p => p.theme.colors.primary};
  }

  ${LabelWrapper} {
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const TravelTime = styled(Caption)`
  grid-area: travelTime;
  text-align: center;
  color: ${p => p.theme.colors.primary};
`

const DotsIconShortStyled = styled(DotsIconShort)<{ $isSmall?: boolean }>`
  width: 20px;
  display: ${p => (!p.$isSmall && p.theme.mediaQueries.tablet ? 'none' : 'block')};
`

const DotsIconLongStyled = styled(DotsIconLong)<{ $isSmall?: boolean }>`
  width: 72px;
  display: ${p => (!p.$isSmall && p.theme.mediaQueries.tablet ? 'block' : 'none')};
`

export default memo(RouteInfoTravelTime)
