import styled from 'styled-components'

import { textSmallRegularStyles } from '@styles/typography/text'

export const Block = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;
`
export const Label = styled.span`
  ${textSmallRegularStyles};
  line-height: 24px;
  color: ${p => p.theme.colors.fontMain};
`
