import { TrainFilters } from '@redux/features/trainFilters/constants/trainFilters.constants'
import type { RootState } from '@redux/store'
import { createSelector } from '@reduxjs/toolkit'

export const selectFilteredTrains = (state: RootState) => state.trainFilters.filteredTrains

export const selectInitTrains = (state: RootState) => state.trainFilters.initTrains

export const selectIsDisabledFilters = (state: RootState) => !Object.values(state.trainFilters.initTrains).length

export const selectAllFilters = (state: RootState) => state.trainFilters.filters

export const selectIsShowNotificationRecommendedStations = (state: RootState) =>
  state.trainFilters.isShowNotificationRecommendedStations

export const selectIsShowShowRecommendedStationBadge = (state: RootState) =>
  state.trainFilters.isShowRecommendedStationBadge

export const selectFilterIsActive = (state: RootState) =>
  JSON.stringify(state.trainFilters.filters) !== JSON.stringify(state.trainFilters.initFilters) ||
  JSON.stringify(state.trainFilters.initTrains) !== JSON.stringify(state.trainFilters.filteredTrains)

const selectedDepartureStation = (state: RootState) => state.trainFilters.filters[TrainFilters.DEPARTURE_STATION]
const selectedArrivalStation = (state: RootState) => state.trainFilters.filters[TrainFilters.ARRIVAL_STATION]

export const selectedOnlyRecommended = createSelector(
  selectedDepartureStation,
  selectedArrivalStation,
  (departure, arrival) => {
    const combineSelected = [...departure, ...arrival].filter(el => el.value)
    if (!combineSelected.length) return false
    return combineSelected.every(el => el.recommended)
  }
)
