import type { FC } from 'react'
import { memo } from 'react'

import type { PassengersCategoryType } from '@Types/common/passengers'
import type { Icon } from '@iconscout/react-unicons'
import { UilKid, UilUser } from '@iconscout/react-unicons'

type Props = {
  category?: PassengersCategoryType
}

const categoryIcons: Record<PassengersCategoryType, Icon> = {
  adult: UilUser,
  child: UilKid,
  infant: UilKid,
}

const PassengersCategoryIcon: FC<Props> = ({ category = 'adult' }) => {
  const Icon = categoryIcons[category]
  return <Icon />
}

export default memo(PassengersCategoryIcon)
