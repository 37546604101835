import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { selectCartData } from '@redux/features/cart/cart.selectors'
import { useAppSelector } from '@redux/hooks'

import Divider from '@base/Divider'
import FareInfo from '@components/fares/FareInfo'
import { outboundDetailsContentId } from '@constants/containers/popup'
import { useCurrencyFormat } from '@hooks/currency/useCurrencyFormat/useCurrencyFormat'
import type { InformationType } from '@hooks/useInformationBlock/useInformationBlock'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import Title from '@typography/Title'
import { ClassSeatInfo } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/ClassSeatInfo/ClassSeatInfo'
import { MobileHeader } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/MobileHeader/MobileHeader'
import { OutboundFareInfo } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/OutboundFareInfo/OutboundFareInfo'
import { OutboundRouteInfo } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/OutboundRouteInfo/OutboundRouteInfo'
import { TotalPriceInfo } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/TotalPriceInfo/TotalPriceInfo'
import { Wrapper } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/styles'

import CarrierInfo from '../../trainInfo/components/CarrierInfo'

type Props = {
  onClose: () => void
  onEdit?: () => void
  setInformation: (data: InformationType) => void
}

export const DetailsPopupContent: FC<Props> = ({ onClose, onEdit, setInformation }) => {
  const departureData = useAppSelector(selectCartData)?.[0]
  const { t } = useTranslation('Timetable')
  const isMobile = useMediaQuery(mediaQueries.mobile)

  const setCoachClassInfo = departureData?.coachClass?.coach_class_description?.description
    ? () =>
        setInformation({
          body: (
            <div
              dangerouslySetInnerHTML={{
                __html: departureData?.coachClass.coach_class_description?.description as string,
              }}
            />
          ),
          header: <Title level={5}>{t('trainInformation')}</Title>,
        })
    : undefined

  const setFareInfoHandle = () =>
    setInformation({
      body: (
        <FareInfo
          cancellationFee={departureData?.fare?.cancellation_fee}
          description={departureData.fare?.fare?.description}
          modificationFee={departureData?.fare?.modification_fee}
        />
      ),
      header: <Title level={5}>{departureData?.fare?.fare?.name || ''}</Title>,
    })

  const priceText = useCurrencyFormat(departureData?.fare?.price || undefined)
  const totalPriceText = useCurrencyFormat(departureData?.fare?.total_price || undefined)
  const seatTypeText = departureData?.coachClass?.seat_type?.name

  const passengersCount = departureData?.passengers.adults + departureData?.passengers.children

  return (
    <Wrapper id={outboundDetailsContentId}>
      {isMobile ? (
        <MobileHeader action={onEdit} actionLabel={t('edit')} onClose={onClose} title={t('outbound')} />
      ) : null}
      <OutboundRouteInfo isMobile={isMobile} onClose={onClose} train={departureData?.train} />
      <Divider />
      {departureData?.train?.carrier && (
        <CarrierInfo
          carrier={departureData.train.carrier}
          trainClassName={departureData.train.train_class.name}
          trainNumber={departureData.train.train_number}
        />
      )}
      <Divider />
      <ClassSeatInfo setCoachClassInfo={setCoachClassInfo} />
      <Divider />
      <OutboundFareInfo
        fare={departureData?.fare}
        priceText={priceText}
        seatTypeText={seatTypeText}
        setFareInfoHandle={setFareInfoHandle}
      />
      <Divider />
      <TotalPriceInfo label={t('totalForPassenger', { count: passengersCount })} totalPrice={totalPriceText} />
    </Wrapper>
  )
}
