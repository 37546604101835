import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { title5Styles } from '@styles/typography/title'

export const Wrapper = styled.header`
  padding: 16px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  background: ${p => p.theme.colors.backgroundMain};
`

export const LeftAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 50px;
`

export const RightAction = styled(LeftAction)`
  justify-content: flex-end;
`

export const Title = styled.span`
  ${title5Styles};
  color: ${p => p.theme.colors.fontMain};
`

export const ButtonHeaderStyled = styled(Button)`
  &.ant-btn {
    svg {
      fill: ${p => p.theme.colors.fontSecondary};
    }

    &:hover {
      svg {
        fill: ${p => p.theme.colors.primary};
      }
    }

    svg:first-child {
      margin-inline: 0;
    }
  }
`
