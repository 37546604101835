import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { setIsOpenAuthorizationPopup } from '@redux/features/authorization/authorization.slice'
import { toggleBurgerMainMenu } from '@redux/features/modals/modals.slice'
import { useAppDispatch } from '@redux/hooks'

import GoHomeHoc, { GoHomeHocStyled } from '@hocs/links/GoHomeHoc'
import { UilBars, UilSearch, UilTicket } from '@iconscout/react-unicons'
import NavBarButton from '@widgets/checkout/timetable/components/mobileNavBar/navBarButton/NavBarButton'

type Props = {
  handlerSearch?: () => void
}

const MobileNavBar: FC<Props> = ({ handlerSearch }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')
  // const isCartDisabled = !useAppSelector(selectCartItemsCount)

  const openAuthPopup = () => {
    dispatch(setIsOpenAuthorizationPopup(true))
  }

  const openMobileMenu = () => {
    dispatch(toggleBurgerMainMenu(true))
  }

  // const openCart = () => {
  //   dispatch(setSimpleModalValue({ name: SimpleModals.cart, value: true }))
  // }

  return (
    <MobileNavBarWrapper>
      <GoHomeHoc>
        <NavBarButton $icon={UilSearch} caption={t('Search')} onClick={handlerSearch} />
      </GoHomeHoc>
      <NavBarButton $icon={UilTicket} caption={t('tickets')} onClick={openAuthPopup} />
      {/*<CartBadgeHoc styles={{ indicator: { right: 'calc(50% - 20px)' }, root: { display: 'grid', width: '100%' } }}>*/}
      {/*  <NavBarButton $icon={UilShoppingBasket} caption={t('Cart')} disabled={isCartDisabled} onClick={openCart} />*/}
      {/*</CartBadgeHoc>*/}
      <NavBarButton $icon={UilBars} caption={t('Menu')} onClick={openMobileMenu} />
    </MobileNavBarWrapper>
  )
}

export const MobileNavBarWrapper = styled.nav`
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  gap: 24px;
  padding: 12px 24px;

  ${GoHomeHocStyled} {
    justify-content: center;
  }
`

export default memo(MobileNavBar)
