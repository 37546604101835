import type { FC } from 'react'

import Counter from '@base/Counter'
import { WrapperCountInput } from '@components/formElements/CountInput'
import type { CountInputProps } from '@components/formElements/CountInput'
import Title from '@typography/Title'

export const CountInput: FC<CountInputProps> = ({ labelIcon, labelText, placeholder, ...props }) => {
  return (
    <WrapperCountInput>
      {labelIcon}
      <Title level={6}>{labelText || placeholder}</Title>
      <Counter {...props} />
    </WrapperCountInput>
  )
}
