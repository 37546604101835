import Form from 'antd/es/form'
import type { FC } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { selectWithLayover } from '@redux/features/search/search.selectors'
import { useAppSelector } from '@redux/hooks'

import CountInput from '@components/formElements/CountInput'
import AgeSelect from '@components/formElements/ageSelect/AgeSelect'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  formHeightDesktop,
  searchFormSizes,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/sizes'
import SaveButton from '@components/mainPage/mainBlock/searchTrains/search/searchForm/saveButton/SaveButton'
import { togglePassengersForm } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/elementsHandlers'
import { fieldsStyles } from '@components/mainPage/mainBlock/searchTrains/search/styles'
import PassengersCategoryIcon from '@components/passengers/passengersCategoryIcon/PassengersCategoryIcon'

import Header from './header/Header'

type Props = {
  adultsCount: number
  childrenAge: (number | undefined)[]
  childrenCount: number
  isOpened: boolean
  maxPassengersCount: number
}

const PassengersForm: FC<Props> = ({ adultsCount, childrenCount, isOpened, maxPassengersCount }) => {
  const { t } = useTranslation(['common', 'Search form'])
  const withLayover = useAppSelector(selectWithLayover)

  return (
    <PassengersFormWrapper $isLayover={withLayover} hidden={!isOpened}>
      <TopPart>
        <Header />
        <Form.Item name={SearchFormKeys.adults}>
          <CountInput
            labelIcon={<PassengersCategoryIcon />}
            labelText={t('adult', { count: adultsCount })}
            max={maxPassengersCount - childrenCount}
            min={1}
          />
        </Form.Item>
      </TopPart>
      <BottomPart>
        <Form.Item name={SearchFormKeys.children}>
          <CountInput
            labelIcon={<PassengersCategoryIcon category="child" />}
            labelText={t('child', { count: childrenCount })}
            max={maxPassengersCount - adultsCount}
            min={0}
          />
        </Form.Item>
        {Array.from(Array(childrenCount)).map((_, index) => (
          <Form.Item
            className="children-age"
            key={index}
            name={[SearchFormKeys.childrenAge, index]}
            rules={[{ required: true }]}
          >
            <AgeSelect label={t('ageOfChild', { ns: 'Search form', order: index + 1 })} />
          </Form.Item>
        ))}
      </BottomPart>
      <SaveButton onClick={togglePassengersForm} />
    </PassengersFormWrapper>
  )
}

const PassengersFormWrapper = styled.div<{ $isLayover?: boolean }>`
  ${fieldsStyles};
  width: ${searchFormSizes.popupWidthDesktop}px;
  height: ${p => (p.$isLayover ? searchFormSizes.layoversHeightDesktop : 0) + formHeightDesktop}px;
  box-shadow: ${p => p.theme.shadows.modal};
  border-radius: ${p => p.theme.borderRadius.default};
  display: flex;
  background: ${p => p.theme.colors.backgroundMain};
  flex-direction: column;

  &[hidden] {
    display: none;
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    width: ${searchFormSizes.popupWidthTablet}px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
`

const TopPart = styled.div`
  display: grid;
  grid-gap: 20px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 15px 24px 24px 24px;
`

const BottomPart = styled.div`
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background: ${p => p.theme.gradient.grayToWhite};
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .children-age {
    margin-top: 24px;
  }
`

export default memo(PassengersForm)
