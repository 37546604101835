import { useTranslation } from 'react-i18next'

import { getTravelTime } from '@utils/dateTime/time'

const useTravelTime = (seconds: number | string) => {
  const { t } = useTranslation('common')

  if (typeof seconds === 'string') return seconds

  return getTravelTime({ hoursCaption: t('hoursShort'), minutesCaption: t('minutesShort'), seconds })
}

export default useTravelTime
