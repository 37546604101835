import dayjs from 'dayjs'
import type { FC } from 'react'

import { selectCartData } from '@redux/features/cart/cart.selectors'
import { useAppSelector } from '@redux/hooks'

import dateFormats from '@constants/dates/dateFormats'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import { Row, StyledText } from '@widgets/checkout/timetable/components/OutboundDate/styles'

type Props = {
  isDetails?: boolean
  isShort?: boolean
}

export const OutboundDate: FC<Props> = ({ isDetails, isShort }) => {
  const departureData = useAppSelector(selectCartData)?.[0]
  const isMobile = useMediaQuery(mediaQueries.mobile)

  if (!departureData) return null

  const date = departureData
    ? dayjs
        .parseZone(departureData.train.departure_datetime)
        .format(dateFormats[isMobile && !isDetails ? '1 Jan' : 'Sun, 1 Jan 2024'])
    : ''

  const departureTime = departureData
    ? dayjs.parseZone(departureData.train.departure_datetime).format(dateFormats['01:01 AM'])
    : ''

  const arrivalTime = departureData
    ? dayjs.parseZone(departureData.train.arrival_datetime).format(dateFormats['01:01 AM'])
    : ''

  if (isShort) {
    return <StyledText $isDetails={isDetails}>{date}</StyledText>
  }

  return (
    <Row id="auto-test-outbound--date">
      <StyledText $isDetails={isDetails}>{date}</StyledText>
      <StyledText $isDetails={isDetails}>{`${departureTime} - ${arrivalTime}`}</StyledText>
    </Row>
  )
}
