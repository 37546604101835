import { Form } from 'antd/es'
import { useEffect, useMemo } from 'react'

import { useGetStationsQuery } from '@redux/features/search/search.api'

type Props = {
  stationKey: string
  stationName?: null | string
}

const useDefaultStation = ({ stationKey, stationName }: Props) => {
  const form = Form.useFormInstance()

  const { currentData: options } = useGetStationsQuery(
    {
      name: stationName || '',
    },
    { skip: !stationName }
  )

  const defaultStation = useMemo(() => {
    return options?.find(el => el.label.toLowerCase() === stationName?.toLowerCase())
  }, [options, stationName])

  useEffect(() => {
    if (defaultStation) {
      form.setFieldsValue({
        [stationKey]: defaultStation,
      })
    }
  }, [defaultStation, form, stationKey])
}

export default useDefaultStation
