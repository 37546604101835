import type { FC } from 'react'

import { UilExclamationCircle } from '@iconscout/react-unicons'
import OutboundClassSeat from '@widgets/checkout/timetable/components/OutboundClassSeat'
import {
  Container,
  InfoButton,
  Row,
} from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/styles'

type Props = {
  setCoachClassInfo: (() => void) | undefined
}

export const ClassSeatInfo: FC<Props> = ({ setCoachClassInfo }) => {
  return (
    <Container $padding="24px" id="auto-test-outbound--content-class-seat-info">
      <Row>
        <OutboundClassSeat />
        {setCoachClassInfo && (
          <InfoButton onClick={setCoachClassInfo} type="link">
            <UilExclamationCircle />
          </InfoButton>
        )}
      </Row>
    </Container>
  )
}
