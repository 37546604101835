import type { FC } from 'react'

import type { ITrain } from '@Types/routes/train'
import { ButtonClose } from '@components/modals/components/TitleWithClose'
import { UilMultiply } from '@iconscout/react-unicons'
import Title from '@typography/Title'
import { OutboundDate } from '@widgets/checkout/timetable/components/OutboundDate/OutboundDate'
import { OutboundRoute } from '@widgets/checkout/timetable/components/OutboundRoute/OutboundRoute'
import { Container, Header } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/styles'
import RouteInfo from '@widgets/checkout/timetable/routeData/trainsList/components/routeInfo/RouteInfo'

type Props = {
  isMobile: boolean
  onClose: () => void
  train?: ITrain
}

export const OutboundRouteInfo: FC<Props> = ({ isMobile, onClose, train }) => {
  return (
    <Container $padding={isMobile ? '24px' : '16px'} id="auto-test-outbound--content-route-info">
      <Header>
        <Title level={4}>
          <OutboundRoute />
        </Title>
        {!isMobile ? (
          <ButtonClose iconColor="fontSecondary" onClick={onClose} type="link">
            <UilMultiply />
          </ButtonClose>
        ) : null}
      </Header>
      <OutboundDate isDetails isShort />
      {train && <RouteInfo cheapestTrain={false} isOnlyRecommendedFilter={true} isSmall train={train} />}
    </Container>
  )
}
