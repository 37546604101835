import styled from 'styled-components'

import { captionUltraSmallStyles } from '@styles/typography/caption'

export const CarrierInfoWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'carrier logo'
    'train logo';
  grid-auto-rows: 16px 16px;
`

export const CarrierName = styled.span`
  grid-area: carrier;
  ${captionUltraSmallStyles};
  color: ${p => p.theme.colors.fontSecondary};
`

export const TrainName = styled.span`
  grid-area: train;
  ${captionUltraSmallStyles};
  line-height: 14px;
  text-transform: none;
  color: ${p => p.theme.colors.fontSecondary};
`

export const Logo = styled.img`
  height: 32px;
  max-width: 170px;
  object-fit: contain;
  justify-self: end;
`
