import Radio, { type RadioChangeEvent } from 'antd/es/radio'
import Space from 'antd/es/space'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { selectTimeTableSort } from '@redux/features/checkout/checkout.selectors'
import { setTimeTableSort } from '@redux/features/checkout/checkout.slice'
import { TimetableFieldSort } from '@redux/features/checkout/types/sort'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import { Wrapper } from '@widgets/checkout/timetable/routeData/trainsList/trainsListHeader/buttonsControl/ButtonSortMenu/styles'

type Props = {
  onClose: () => void
}

export const ButtonSortMenu: FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const selectedSort = useAppSelector(selectTimeTableSort)
  const { t } = useTranslation('Timetable')

  const onChange = (e: RadioChangeEvent) => {
    dispatch(setTimeTableSort(e.target.value as TimetableFieldSort))
    onClose()
  }

  return (
    <Wrapper>
      <Radio.Group onChange={onChange} value={selectedSort}>
        <Space direction="vertical" size={16}>
          <Radio value={TimetableFieldSort.DEPARTURE}>{t('departure')}</Radio>
          <Radio value={TimetableFieldSort.SPEED}>{t('speed')}</Radio>
          <Radio value={TimetableFieldSort.PRICE}>{t('price')}</Radio>
        </Space>
      </Radio.Group>
    </Wrapper>
  )
}
