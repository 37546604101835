import type { FC } from 'react'

import { UilArrowLeft } from '@iconscout/react-unicons'
import {
  ButtonHeaderStyled,
  LeftAction,
  RightAction,
  Title,
  Wrapper,
} from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/MobileHeader/styles'
import { StyledButton } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/styles'

type Props = {
  action?: () => void
  actionLabel?: string
  onClose: () => void
  title: string
}

export const MobileHeader: FC<Props> = ({ action, actionLabel, onClose, title }) => {
  return (
    <Wrapper>
      <LeftAction>
        <ButtonHeaderStyled onClick={onClose} type="link">
          <UilArrowLeft />
        </ButtonHeaderStyled>
      </LeftAction>
      <Title>{title}</Title>
      <RightAction>
        <StyledButton color="buttonText" onClick={action}>
          {actionLabel}
        </StyledButton>
      </RightAction>
    </Wrapper>
  )
}
