import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { IFare } from '@Types/routes/fares'
import { UilExclamationCircle } from '@iconscout/react-unicons'
import Text from '@typography/Text'
import Title from '@typography/Title'
import FareAvailableActionsView from '@widgets/checkout/timetable/components/FareAvailableActionsView'
import {
  Column,
  Container,
  InfoButton,
  Row,
} from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/styles'

type Props = {
  fare?: IFare
  priceText: string
  seatTypeText?: null | string
  setFareInfoHandle: () => void
}

export const OutboundFareInfo: FC<Props> = ({ fare, priceText, seatTypeText, setFareInfoHandle }) => {
  const { t } = useTranslation('Fare info')

  return (
    <Container $padding="24px" id="auto-test-outbound--content-fare-info">
      <Row>
        <Text size="l" variant="medium">
          {fare?.fare?.name}
        </Text>
        <Title level={6}>
          {priceText}
          {seatTypeText ? ` / ${seatTypeText}` : null}
        </Title>
      </Row>
      <Row>
        <Column>
          <FareAvailableActionsView isAvailable={fare?.fare?.modifiable} label={t('modification')} />
          <FareAvailableActionsView isAvailable={fare?.fare?.refundable} label={t('cancellation')} />
        </Column>
        <InfoButton onClick={setFareInfoHandle} type="link">
          <UilExclamationCircle />
        </InfoButton>
      </Row>
    </Container>
  )
}
