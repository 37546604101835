import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import OutboundClassSeat from '@widgets/checkout/timetable/components/OutboundClassSeat'
import { OutboundDate } from '@widgets/checkout/timetable/components/OutboundDate/OutboundDate'
import OutboundRoute from '@widgets/checkout/timetable/components/OutboundRoute'
import { InfoViewWrapper, Station, Title } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/styles'

type Props = {
  isMobile: boolean
  isTablet: boolean
}

export const InfoView: FC<Props> = ({ isMobile, isTablet }) => {
  const { t } = useTranslation('Timetable')
  return (
    <InfoViewWrapper>
      <Title id="auto-test-outbound--title">{t(isMobile ? 'outbound' : 'outboundTrip')}</Title>
      {!isTablet && (
        <Station id="auto-test-outbound--station">
          <OutboundRoute />
        </Station>
      )}
      <OutboundDate />
      {!isMobile ? <OutboundClassSeat /> : null}
    </InfoViewWrapper>
  )
}
