import type { FC } from 'react'

import Text from '@typography/Text'
import Title from '@typography/Title'
import { Container, Row } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent/styles'

type Props = {
  label: string
  totalPrice: string
}

export const TotalPriceInfo: FC<Props> = ({ label, totalPrice }) => {
  return (
    <Container $padding="24px" id="auto-test-outbound--content-total-price-info">
      <Row>
        <Text size="l" variant="medium">
          {label}
        </Text>
        <Title level={6}>{totalPrice}</Title>
      </Row>
    </Container>
  )
}
