import type { FC } from 'react'
import { useTheme } from 'styled-components'

import { UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons'

import { Block, Label } from './styles'

type Props = {
  isAvailable?: boolean
  label?: string
}
export const FareAvailableActionsView: FC<Props> = ({ isAvailable, label }) => {
  const theme = useTheme()
  if (!label) return null

  return (
    <Block>
      {isAvailable ? <UilCheckCircle color={theme.colors.green} /> : <UilTimesCircle color={theme.colors.red} />}
      <Label>{label}</Label>
    </Block>
  )
}
