import dayjs from 'dayjs'
import { type FC, memo } from 'react'

import type { ITrain } from '@Types/routes/train'
import dateFormats from '@constants/dates/dateFormats'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import Title from '@typography/Title'
import RouteInfoTravelTime from '@widgets/checkout/timetable/routeData/trainsList/components/routeInfo/routeInfoTime/routeInfoTravelTime/RouteInfoTravelTime'
import {
  Text,
  Wrapper,
} from '@widgets/checkout/timetable/routeData/trainsList/components/routeInfo/routeInfoTime/styles'

type Props = {
  isSmall?: boolean
  train: ITrain
}

const RouteInfoTime: FC<Props> = ({
  isSmall,
  train: { arrival_datetime, change_station, departure_datetime, running_time },
}) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const isDesktop = useMediaQuery(mediaQueries.onlyDesktop)
  const titleLevel = isMobile ? 6 : 4

  if (isSmall || isDesktop) {
    return (
      <Wrapper>
        <Title level={titleLevel}>{dayjs.parseZone(departure_datetime).format(dateFormats['01:01'])}</Title>
        <Text>{dayjs.parseZone(departure_datetime).format(dateFormats['AM'])}</Text>
        <RouteInfoTravelTime isSmall runningTime={running_time} trainChange={!!change_station} />
        <Title level={titleLevel}>{dayjs.parseZone(arrival_datetime).format(dateFormats['01:01'])}</Title>
        <Text>{dayjs.parseZone(arrival_datetime).format(dateFormats['AM'])}</Text>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Title level={titleLevel}>{dayjs.parseZone(departure_datetime).format(dateFormats['01:01 AM'])}</Title>
      <RouteInfoTravelTime isSmall={isDesktop || isMobile} runningTime={running_time} trainChange={!!change_station} />
      <Title level={titleLevel}>{dayjs.parseZone(arrival_datetime).format(dateFormats['01:01 AM'])}</Title>
    </Wrapper>
  )
}

export default memo(RouteInfoTime)
