import type { FC } from 'react'

import { selectCartData } from '@redux/features/cart/cart.selectors'
import { useAppSelector } from '@redux/hooks'

import { StyledText } from '@widgets/checkout/timetable/components/OutboundClassSeat/styles'

export const OutboundClassSeat: FC = () => {
  const departureData = useAppSelector(selectCartData)?.[0]

  if (!departureData) return null

  return <StyledText id="auto-test-outbound--class-seat">{departureData?.coachClass.coach_class.name}</StyledText>
}
