import styled from 'styled-components'

import { textMediumMediumStyles } from '@styles/typography/text'

export const Wrapper = styled.div`
  background: ${p => p.theme.colors.backgroundMain};
  border-radius: ${p => p.theme.borderRadius.default};
  overflow: hidden;
  display: inline-flex;
  box-shadow: ${p => p.theme.shadows.modal};
  padding: 24px;

  .ant-radio-wrapper {
    span:last-of-type {
      ${textMediumMediumStyles};
      padding: 0 12px;
    }

    .ant-radio-inner {
      &:after {
        background-color: ${p => p.theme.colors.backgroundMain};
      }
    }
  }
`
