import { type FC, memo } from 'react'
import styled from 'styled-components'

import type { IStation } from '@Types/stations/station'
import { ellipsisText } from '@styles/typography/textStyles'
import { CaptionStyled } from '@typography/Caption'

import Station from './Station'

type Props = {
  arrivalStation: IStation
  changeStation?: IStation | null
  departureStation: IStation
}

const RouteInfoStations: FC<Props> = ({ arrivalStation, changeStation, departureStation }) => {
  return (
    <Wrapper $withChange={!!changeStation}>
      <Station stationName={departureStation.single_name || departureStation.address.locality || ''} />
      {changeStation && <Station stationName={changeStation?.single_name || changeStation.address.locality || ''} />}
      <Station stationName={arrivalStation?.single_name || arrivalStation.address.locality || ''} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $withChange: boolean }>`
  display: grid;
  grid-template-columns: repeat(${p => (p.$withChange ? '3' : '2')}, 1fr);
  color: ${p => p.theme.colors.fontSecondary};
  gap: 12px;

  ${p => p.theme.mediaQueries.mobileM} {
    margin-block-start: ${p => (p.$withChange ? '10px' : 0)};
  }

  ${p => p.theme.mediaQueries.mobileS} {
    margin-block-start: ${p => (p.$withChange ? '20px' : 0)};
  }

  ${CaptionStyled} {
    ${ellipsisText};
    color: ${p => p.theme.colors.fontSecondary};
    max-width: 100%;

    &:not(:first-child) {
      margin-inline-start: 32px;
      text-align: end;
      justify-self: end;
    }

    ${p =>
      p.$withChange &&
      `&:nth-child(2) {
        margin-inline-start: 0;
        justify-self: center;
        text-align: center;
      }`}
  }
`

export default memo(RouteInfoStations)
