import styled from 'styled-components'

import { UilArrowLeft, UilArrowRight } from '@iconscout/react-unicons'

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
  background: ${p => p.theme.colors.backgroundAdditional};

  &:hover {
    background: ${p => p.theme.colors.backgroundBlueContainer};
    transition: 0.3s;
  }

  svg {
    fill: ${p => p.theme.colors.icon};
  }
`

export const datePickerButtons = {
  nextIcon: (
    <IconWrapper>
      <UilArrowRight />
    </IconWrapper>
  ),
  prevIcon: (
    <IconWrapper>
      <UilArrowLeft />
    </IconWrapper>
  ),
  superNextIcon: null,
  superPrevIcon: null,
}
