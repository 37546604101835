import Popover from 'antd/es/popover'
import { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleModals } from '@redux/features/modals/constants/simpleModals'
import { setSimpleModalValue } from '@redux/features/modals/modals.slice'
import { useAppDispatch } from '@redux/hooks'

import type { InformationType } from '@hooks/useInformationBlock/useInformationBlock'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'
import DetailsPopupContent from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent'
import { Actions, StyledButton } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/styles'

type Props = {
  onEdit: () => void
  setInformation: (data: InformationType) => void
}

export const OutboundInfoActions: FC<Props> = ({ onEdit, setInformation }) => {
  const [openDetails, setOpenDetails] = useState(false)
  const isMobile = useMediaQuery(mediaQueries.mobile)
  const isTablet = useMediaQuery(mediaQueries.tablet)
  const { t } = useTranslation('Timetable')
  const dispatch = useAppDispatch()

  const onOpenChange = (open: boolean) => setOpenDetails(open)
  const onClose = () => setOpenDetails(false)
  const onOpenMobileDetails = () => {
    dispatch(setSimpleModalValue({ name: SimpleModals.MOBILE_DETAILS, value: true }))
  }

  return (
    <Actions>
      {!isMobile ? (
        <Popover
          arrow={false}
          content={<DetailsPopupContent onClose={onClose} setInformation={setInformation} />}
          onOpenChange={onOpenChange}
          open={openDetails}
          overlayInnerStyle={{ padding: 0 }}
          overlayStyle={{ width: 390 }}
          placement={isTablet ? 'left' : 'bottomRight'}
          trigger="click"
        >
          <StyledButton color="buttonText" id="auto-test-outbound--details">
            {t('details')}
          </StyledButton>
        </Popover>
      ) : (
        <StyledButton color="buttonText" id="auto-test-outbound--details" onClick={onOpenMobileDetails}>
          {t('details')}
        </StyledButton>
      )}

      <StyledButton color="buttonText" id="auto-test-outbound--edit" onClick={onEdit}>
        {t('edit')}
      </StyledButton>
    </Actions>
  )
}
