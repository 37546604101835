import type { FC } from 'react'
import styled from 'styled-components'

import LabelAndDate from '@components/headerInfoRouteMobile/labelAndDate/LabelAndDate'
import type { RouteAndFilterProps } from '@components/headerInfoRouteMobile/routeAndFilter/RouteAndFilter'
import RouteAndFilter from '@components/headerInfoRouteMobile/routeAndFilter/RouteAndFilter'
import { OutboundTripInfo } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/OutboundTripInfo'

type Props = {
  DepartureDate: FC
  isSecondLeg?: boolean
  label: string
  RouteStation: RouteAndFilterProps['RouteStation']
}

const HeaderInfoRouteMobile: FC<Props> = ({ DepartureDate, isSecondLeg, label, RouteStation }) => {
  return (
    <HeaderInfoRouteMobileWrapper>
      <LabelAndDate DepartureDate={DepartureDate} label={label} showBackArrow={!isSecondLeg} />
      {isSecondLeg ? <OutboundTripInfo /> : null}
      <RouteAndFilter isHideBorder={isSecondLeg} RouteStation={RouteStation} />
    </HeaderInfoRouteMobileWrapper>
  )
}

const HeaderInfoRouteMobileWrapper = styled.header`
  display: grid;
  grid-template-rows: auto auto auto;
  width: 100vw;
`

export default HeaderInfoRouteMobile
