import { Badge } from 'antd/es'
import type { FC } from 'react'
import { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'

import { SimpleModals } from '@redux/features/modals/constants/simpleModals'
import { setSimpleModalValue } from '@redux/features/modals/modals.slice'
import { selectFilterIsActive, selectIsDisabledFilters } from '@redux/features/trainFilters/trainFilters.selectors'
import { resetFilters } from '@redux/features/trainFilters/trainFilters.slice'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import Button from '@base/buttons/Button'
import { UilSlidersV } from '@iconscout/react-unicons'

const ButtonFilters: FC = () => {
  const isDisabled = useAppSelector(selectIsDisabledFilters)
  const filterIsActive = useAppSelector(selectFilterIsActive)
  const theme = useTheme()
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetFilters())
    }
  }, [dispatch])

  const handlerOpenFilter = () => {
    dispatch(setSimpleModalValue({ name: SimpleModals.FILTERS, value: true }))
  }

  return (
    <BadgeStyled color={theme.colors.red} dot={filterIsActive}>
      <Button disabled={isDisabled} onClick={handlerOpenFilter} type="link">
        <UilSlidersV />
      </Button>
    </BadgeStyled>
  )
}

const BadgeStyled = styled(Badge)`
  &.ant-badge .ant-badge-dot {
    height: 7px;
    width: 7px;
    inset-inline-end: 1px;
  }
`

export default ButtonFilters
