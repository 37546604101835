import { Timeline } from 'antd/es'
import type { FC } from 'react'
import styled from 'styled-components'

import { ellipsisText } from '@styles/typography/textStyles'
import Caption, { CaptionStyled } from '@typography/Caption'
import ButtonsControl from '@widgets/checkout/timetable/routeData/trainsList/trainsListHeader/buttonsControl/ButtonsControl'

export type RouteAndFilterProps = {
  isHideBorder?: boolean
  RouteStation: FC<{ stationType: 'arrival_station' | 'departure_station' }>
}

const RouteAndFilter: FC<RouteAndFilterProps> = ({ isHideBorder, RouteStation }) => {
  return (
    <Wrapper $isHideBorder={isHideBorder}>
      <TimelineStyled
        items={[
          {
            children: (
              <Caption size="s">
                <RouteStation stationType="departure_station" />
              </Caption>
            ),
          },
          {
            children: (
              <Caption size="s">
                <RouteStation stationType="arrival_station" />
              </Caption>
            ),
          },
        ]}
      />
      <ButtonsControl />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $isHideBorder?: boolean }>`
  width: inherit;
  padding: 10px 24px;
  min-height: 40px;
  border-bottom: ${p => (p.$isHideBorder ? 'none' : `1px solid ${p.theme.colors.border}`)};
  display: inline-flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`

const TimelineStyled = styled(Timeline)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  width: 100%;
  height: 40px;

  ${CaptionStyled} {
    ${ellipsisText};
    display: block;
    color: ${p => p.theme.colors.fontSecondary};
  }

  .ant-timeline-item {
    display: inline-flex;
    align-items: center;
    height: 18px;
    padding: 0;

    &:last-child {
      padding: 0;
    }

    .ant-timeline-item-head {
      border: none;
      background: ${p => p.theme.colors.primary};
      height: 7px;
      width: 7px;
    }

    .ant-timeline-item-content {
      min-height: auto;
      inset-block-start: 0;
    }

    .ant-timeline-item-tail {
      height: 100%;
      inset-inline-start: 3px;
      border-inline-start: 1px solid ${p => p.theme.colors.primary};
    }
  }
`

export default RouteAndFilter
