import type { FC } from 'react'

import { selectCartData } from '@redux/features/cart/cart.selectors'
import { useAppSelector } from '@redux/hooks'

import useStationName from '@hooks/useStationName/useStationName'

export const OutboundRoute: FC = () => {
  const departureData = useAppSelector(selectCartData)?.[0]

  const departure = useStationName(departureData?.train.departure_station)
  const arrival = useStationName(departureData?.train.arrival_station)

  return `${departure} - ${arrival}`
}
