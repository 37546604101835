import styled from 'styled-components'

import { title7Styles, title9Styles } from '@styles/typography/title'

export const Row = styled.div`
  display: flex;
  column-gap: 6px;
`

export const StyledText = styled.span<{ $isDetails?: boolean }>`
  ${title7Styles};
  color: ${p => p.theme.colors.fontSecondary};

  ${p => p.theme.mediaQueries.mobile} {
    ${p => (p.$isDetails ? title7Styles : title9Styles)}
  }
`
