import type { FC } from 'react'

import { CarrierInfoWrapper, CarrierName, Logo, TrainName } from './styles'

type Props = {
  carrier: ICarrier
  trainClassName?: string
  trainNumber: null | string
}

export const CarrierInfo: FC<Props> = ({ carrier, trainClassName, trainNumber }) => {
  return (
    <CarrierInfoWrapper>
      <CarrierName>{carrier?.name}</CarrierName>
      <TrainName>{`${trainClassName ? `${trainClassName} ` : ''} ${trainNumber ? trainNumber : ''}`}</TrainName>
      <Logo alt={`${carrier?.name}`} src={carrier?.logo?.uri?.url} />
    </CarrierInfoWrapper>
  )
}
