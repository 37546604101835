import type { FC, ReactNode } from 'react'
import type { RuleSet } from 'styled-components'
import styled, { css, useTheme } from 'styled-components'

import type { ThemeColorsType } from '@themes/themes'
import Caption from '@typography/Caption'

export type ColorsType = 'black' | 'blue' | 'empty' | 'frosty' | 'sky' | 'white'
type SizeType = 'l' | 's'

type Props = {
  children: ReactNode
  color?: ColorsType | undefined
  size?: SizeType
}

const styles: Record<SizeType, RuleSet<object>> = {
  l: css`
    padding: 7px 16px;
  `,
  s: css`
    padding: 4px 10px;
  `,
}

const captionColors: Record<ColorsType, ThemeColorsType> = {
  black: 'white',
  blue: 'white',
  empty: 'fontEmptyLabel',
  frosty: 'buttonText',
  sky: 'buttonText',
  white: 'primary',
}

export const BackgroundColors: Record<ColorsType, ThemeColorsType> = {
  black: 'blackLite',
  blue: 'primary',
  empty: 'backgroundEmptyLabel',
  frosty: 'borderFrosty',
  sky: 'borderBlue',
  white: 'white',
}

const Label: FC<Props> = ({ children, color = 'blue', size = 'l' }) => {
  const theme = useTheme()
  const textColor = captionColors[color]

  return (
    <LabelWrapper $isEmpty={color === 'empty'} color={BackgroundColors[color]} size={size}>
      <Caption size={size === 'l' ? 's' : 'xs'} style={{ color: theme?.colors[textColor] }}>
        {children}
      </Caption>
    </LabelWrapper>
  )
}

export const LabelWrapper = styled.div<{ $isEmpty: boolean; color: ThemeColorsType; size: SizeType }>`
  ${p => styles[p.size]};
  z-index: 1;
  width: fit-content;
  min-width: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background: ${p => p.theme.colors[p.color]};
  border: 1px solid ${p => (p.$isEmpty ? `${p.theme.colors.borderBlue}` : 'transparent')};

  &,
  span {
    transition: ${p => p.theme.transitions.default};
  }
`

export default Label
