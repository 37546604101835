import type { ButtonProps } from 'antd/es'
import type { FC } from 'react'
import styled from 'styled-components'

import Button from '@base/buttons/Button'
import type { Icon } from '@iconscout/react-unicons'
import Caption, { CaptionStyled } from '@typography/Caption'

type Props = {
  $icon: Icon
  caption: string
} & ButtonProps

const NavBarButton: FC<Props> = ({ $icon: Icon, caption, ...props }) => {
  return (
    <StyledButton {...props} type="link">
      <Icon />
      <Caption size="xs">{caption}</Caption>
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  &.ant-btn {
    flex-direction: column;
    justify-content: space-between;
    height: 40px;

    &.ant-btn-link svg:first-child:not(&.ant-btn.ant-btn-link svg:last-child) {
      margin-inline: 0;
    }

    ${CaptionStyled} {
      color: ${p => p.theme.colors.fontSecondary};
      line-height: 100%;
    }
  }
`

export default NavBarButton
