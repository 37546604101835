import dayjs from 'dayjs'

import type { SearchStationsOptionType } from '@redux/features/search/types/searchStations'

import type { ISearchHistory } from '@Types/common/search'
import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import type { SearchFormValuesType } from '@components/mainPage/mainBlock/searchTrains/search/types/formTypes'
import { dateValidators } from '@validation/rules/date'

import getStationFormValues from './getStationFormValues'

export const getInitialValues = ({
  arrival,
  departure,
  history,
}: {
  arrival?: SearchStationsOptionType
  departure?: SearchStationsOptionType
  history?: ISearchHistory
}): SearchFormValuesType => {
  const { legs, passengers } = history || {}
  const { arrival_station, departure_date, departure_station } = legs?.[1] || {}
  const departureHistoryDate = typeof departure_date === 'string' ? dayjs(departure_date) : undefined
  const isCorrectHistoryDeparture = departureHistoryDate && dateValidators.isFutureDayOrToday(departureHistoryDate)
  const departureDate = isCorrectHistoryDeparture ? departureHistoryDate : dayjs().add(7, 'days')
  const returnDate = isCorrectHistoryDeparture && legs?.[2] ? dayjs(legs[2].departure_date) : undefined

  return {
    [SearchFormKeys.adults]: passengers?.adults || 1,
    [SearchFormKeys.arrival]: arrival || (arrival_station ? getStationFormValues(arrival_station) : undefined),
    [SearchFormKeys.children]: passengers?.children || 0,
    [SearchFormKeys.childrenAge]: passengers?.children_age || [],
    [SearchFormKeys.date]: legs?.[2] && returnDate ? [departureDate, returnDate] : [departureDate, null],
    [SearchFormKeys.departure]: departure || (departure_station ? getStationFormValues(departure_station) : undefined),
  }
}
