import type { InputNumberProps } from 'antd/es'
import type { FC } from 'react'

import { InputNumberStyled } from '@base/Counter/styles'
import TextButton from '@base/buttons/TextButton/TextButton'
import { UilPlus } from '@iconscout/react-unicons'
import { UilMinus } from '@iconscout/react-unicons'

export const Counter: FC<InputNumberProps<number>> = props => {
  return (
    <InputNumberStyled
      controls={false}
      variant="borderless"
      {...props}
      addonAfter={
        <TextButton
          disabled={Number.isInteger(props.max) && props.max! <= (props.value || 0)}
          onClick={() => props.onChange && props.onChange((props.value || 0) + ((props.step as number) || 1))}
        >
          <UilPlus />
        </TextButton>
      }
      addonBefore={
        <TextButton
          disabled={Number.isInteger(props.min) && props.min! >= (props.value || 0)}
          onClick={() => props.onChange && props.onChange((props.value || 0) - ((props.step as number) || 1))}
        >
          <UilMinus />
        </TextButton>
      }
    />
  )
}
