import type { FC } from 'react'

import Divider from '@base/Divider'
import ButtonSort from '@widgets/checkout/timetable/routeData/trainsList/trainsListHeader/buttonsControl/ButtonSort'
import ButtonFilters from '@widgets/checkout/timetable/routeData/trainsList/trainsListHeader/buttonsControl/buttonFilters/ButtonFilters'
import { Wrapper } from '@widgets/checkout/timetable/routeData/trainsList/trainsListHeader/buttonsControl/styles'

const ButtonsControl: FC = () => {
  return (
    <Wrapper>
      <ButtonSort />
      <Divider dashed={false} type="vertical" />
      <ButtonFilters />
    </Wrapper>
  )
}

export default ButtonsControl
