import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { togglePassengersForm } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/elementsHandlers'
import { UilAngleLeft } from '@iconscout/react-unicons'
import { title6Styles } from '@styles/typography/title'
import Title from '@typography/Title'

const Header: FC = () => {
  const { t } = useTranslation('Search form')

  return (
    <Wrapper>
      <Button onClick={togglePassengersForm} />
      <Title level={5}>{t('passengers')}</Title>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: inline-flex;
  justify-content: center;
  position: relative;

  ${p => p.theme.mediaQueries.mobile} {
    margin-top: 16px;
    h5 {
      ${title6Styles};
    }
  }
`

const Button = styled(UilAngleLeft)`
  display: none;

  ${p => p.theme.mediaQueries.mobile} {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    fill: ${p => p.theme.colors.fontSecondary};
  }
`

export default Header
