import styled from 'styled-components'

import { StyledDivider } from '@base/Divider'
import Button from '@base/buttons/Button'
import { textLargeMediumStyles } from '@styles/typography/text'
import { StyledText as ClassSeatText } from '@widgets/checkout/timetable/components/OutboundClassSeat/styles'
import { CarrierInfoWrapper } from '@widgets/checkout/timetable/routeData/trainInfo/components/CarrierInfo'
import { RouteInfoWrapper } from '@widgets/checkout/timetable/routeData/trainsList/components/routeInfo/RouteInfo'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${RouteInfoWrapper} {
    padding: 0;
    min-width: auto;
  }

  ${StyledDivider} {
    margin: 0;
  }

  ${CarrierInfoWrapper} {
    padding: 16px;

    ${p => p.theme.mediaQueries.mobile} {
      padding: 24px;
    }
  }

  ${ClassSeatText} {
    ${textLargeMediumStyles};
    color: ${p => p.theme.colors.fontMain};
  }

  ${p => p.theme.mediaQueries.mobile} {
    background: ${p => p.theme.gradient.grayToWhite};
  }
`

export const Container = styled.div<{ $padding?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: ${p => p.$padding || '16px'};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const InfoButton = styled(Button)`
  &.ant-btn.ant-btn-link {
    margin-inline-start: auto;
  }

  svg {
    fill: ${p => p.theme.colors.fontMain};
  }
`
