import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { selectSearchForms } from '@redux/features/checkout/checkout.selectors'
import { cleanCheckoutOnNewSearch } from '@redux/features/checkout/checkout.slice'
import { SimpleModals } from '@redux/features/modals/constants/simpleModals'
import { setSimpleModalValue } from '@redux/features/modals/modals.slice'
import { onSearch } from '@redux/features/search/thunks'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import InformationBlock from '@components/popups/informationBlock/InformationBlock'
import { outboundDetailsContentId } from '@constants/containers/popup'
import { drawerZIndexes } from '@constants/zIndexes/drawerZIndexes'
import DrawerModal from '@hocs/modals/DrawerModal'
import type { InformationType } from '@hooks/useInformationBlock/useInformationBlock'
import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import useToggle from '@hooks/useToggle/useToggle'
import { mediaQueries } from '@themes/mediaQueries'
import DetailsPopupContent from '@widgets/checkout/timetable/routeData/OutboundTripInfo/DetailsPopupContent'
import InfoView from '@widgets/checkout/timetable/routeData/OutboundTripInfo/InfoView'
import { OutboundInfoActions } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/OutboundInfoActions/OutboundInfoActions'
import { Wrapper } from '@widgets/checkout/timetable/routeData/OutboundTripInfo/styles'

export const OutboundTripInfo: FC = () => {
  const [information, setInformation] = useState<InformationType>({ body: null, header: null })
  const { on: isOpenedInfo, toggleOff: closeInfo, toggleOn: openInfo } = useToggle()

  const dispatch = useAppDispatch()
  const outboundForm = useAppSelector(selectSearchForms)?.[0]
  const returnForm = useAppSelector(selectSearchForms)?.[1]
  const isTablet = useMediaQuery(mediaQueries.tablet)
  const isMobile = useMediaQuery(mediaQueries.mobile)

  useEffect(() => {
    information.body && openInfo()
  }, [information, openInfo])

  const onClickEdit = () => {
    if (outboundForm) {
      const layover = outboundForm.layover_station
      const departureDate = dayjs(outboundForm.departure_date)
      const returnDate = dayjs(returnForm.departure_date)
      const searchDate = layover ? [departureDate, null] : [departureDate, returnDate]

      const payload = {
        ...outboundForm,
        [SearchFormKeys.date]: searchDate as [Dayjs, Dayjs | null],
      }
      void dispatch(onSearch(payload))
      dispatch(cleanCheckoutOnNewSearch())
      dispatch(setSimpleModalValue({ name: SimpleModals.MOBILE_DETAILS, value: false }))
    }
  }

  const onCloseMobileDetails = () => {
    dispatch(setSimpleModalValue({ name: SimpleModals.MOBILE_DETAILS, value: false }))
  }

  return (
    <div>
      <Wrapper>
        <InfoView isMobile={isMobile} isTablet={isTablet} />
        <OutboundInfoActions onEdit={onClickEdit} setInformation={setInformation} />
      </Wrapper>
      <DrawerModal modalName={SimpleModals.MOBILE_DETAILS}>
        <DetailsPopupContent onClose={onCloseMobileDetails} onEdit={onClickEdit} setInformation={setInformation} />
      </DrawerModal>
      <InformationBlock
        getContainer={isMobile ? false : `#${outboundDetailsContentId}`}
        rootStyle={{ position: 'absolute' }}
        zIndex={isMobile ? drawerZIndexes.secondLayerZIndex : drawerZIndexes.defaultZIndex}
        {...information}
        close={closeInfo}
        isOpened={isOpenedInfo}
      />
    </div>
  )
}
