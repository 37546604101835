export const getTravelTime = ({
  hoursCaption,
  minutesCaption,
  seconds,
}: {
  hoursCaption: string
  minutesCaption: string
  seconds: number
}): string => {
  seconds = Math.abs(seconds)
  let time = ''
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  if (hours) {
    time = `${hours} ${hoursCaption}`
  }
  if (minutes) {
    time += ` ${minutes} ${minutesCaption}`
  }
  return time
}
