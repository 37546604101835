import type { FC } from 'react'
import styled from 'styled-components'

import SiteLogo from '@components/SiteLogo/SiteLogo'
import GoHomeHoc from '@hocs/links/GoHomeHoc'
import { UilArrowLeft } from '@iconscout/react-unicons'
import Caption, { CaptionStyled } from '@typography/Caption'
import Title from '@typography/Title'

type Props = {
  DepartureDate: FC
  label: string
  showBackArrow?: boolean
}
const LabelAndDate: FC<Props> = ({ DepartureDate, label, showBackArrow }) => {
  return (
    <Wrapper>
      <GoHomeHoc>{showBackArrow ? <UilArrowLeft style={{ marginBlock: '12px' }} /> : <HSTLogoShortStyled />}</GoHomeHoc>
      <Title level={5}>{label}</Title>
      <Caption size="m">
        <DepartureDate />
      </Caption>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 16px 24px 13px 24px;
  border-bottom: 1px solid ${p => p.theme.colors.border};
  display: inline-flex;
  gap: 16px;
  align-items: center;
  width: 100%;

  ${CaptionStyled} {
    color: ${p => p.theme.colors.fontSecondary};
    margin-inline-start: auto;
  }
`

const HSTLogoShortStyled = styled(() => <SiteLogo isShort />)`
  height: 30px;
`

export default LabelAndDate
