import type { PopoverProps } from 'antd/es'
import dynamic from 'next/dynamic'
import type { FC, ReactNode } from 'react'
import { memo } from 'react'

import useMediaQuery from '@hooks/useMediaQuery/useMediaQuery'
import { mediaQueries } from '@themes/mediaQueries'

import PassengersForm from './components/PassengersForm'
import PassengersLabel from './components/PassengersLabel'

const MobileContent = dynamic(() => import('./components/MobileContent'))
const DesktopContent = dynamic(() => import('./components/DesktopContent'))

type Props = {
  adultsCount: number
  childrenAge: (number | undefined)[]
  childrenCount: number
  close: () => void
  compactLabelView?: boolean
  iconsView?: boolean
  isOpened: boolean
  maxPassengersCount: number
  open: () => void
  popoverProps?: PopoverProps
}

const Passengers: FC<Props> = ({
  adultsCount,
  childrenAge,
  childrenCount,
  close,
  compactLabelView,
  iconsView,
  isOpened,
  maxPassengersCount,
  open,
  popoverProps,
}) => {
  const isMobile = useMediaQuery(mediaQueries.mobile)

  const formContent: ReactNode = (
    <PassengersForm
      adultsCount={adultsCount}
      childrenAge={childrenAge}
      childrenCount={childrenCount}
      isOpened={isOpened}
      maxPassengersCount={maxPassengersCount}
    />
  )

  const labelContent: ReactNode = (
    <PassengersLabel
      adultsCount={adultsCount}
      childrenAge={childrenAge}
      childrenCount={childrenCount}
      compactLabelView={compactLabelView}
      iconsView={iconsView}
    />
  )

  if (isMobile)
    return (
      <MobileContent
        close={close}
        formContent={formContent}
        isOpened={isOpened}
        labelContent={labelContent}
        open={open}
      />
    )

  return (
    <DesktopContent
      close={close}
      formContent={formContent}
      isOpened={isOpened}
      labelContent={labelContent}
      open={open}
      popoverProps={popoverProps}
    />
  )
}

export default memo(Passengers)
