import styled from 'styled-components'

import { textMediumRegularStyles } from '@styles/typography/text'

export const Wrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;

  ${p => p.theme.mediaQueries.mobile} {
    margin-bottom: 2px;
  }
`

export const Text = styled.span`
  ${textMediumRegularStyles};
  position: relative;
  top: 2px;
  line-height: 32px;
  letter-spacing: -0.048px;
  margin-inline-start: 4px;
`
