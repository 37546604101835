import type { ValidateErrorEntity } from 'rc-field-form/es/interface'

import { SearchFormKeys } from '@components/mainPage/mainBlock/searchTrains/search/searchForm/constants/form'
import {
  focusArrivalStation,
  focusDepartureStation,
  selectFirstLayover,
  togglePassengersForm,
} from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/elementsHandlers'

const fieldsHandlers = {
  [SearchFormKeys.arrival]: focusArrivalStation,
  [SearchFormKeys.childrenAge]: togglePassengersForm,
  [SearchFormKeys.departure]: focusDepartureStation,
  [SearchFormKeys.layover]: selectFirstLayover,
}

export const onErrorHandler = ({ errorFields }: ValidateErrorEntity) => {
  const firstError = errorFields[0]

  for (const field in fieldsHandlers) {
    if (firstError.name.includes(field)) {
      fieldsHandlers[field as keyof typeof fieldsHandlers]()
      return
    }
  }
}
