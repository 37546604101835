import type { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from '@base/buttons/Button'

type Props = {
  onClick: (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void
}

const SaveButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('Search form')

  return (
    <StyledButton onClick={onClick} type="primary">
      {t('save')}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  &.ant-btn {
    display: none;

    ${p => p.theme.mediaQueries.mobile} {
      display: block;
      width: calc(100% - 32px);
      margin: 0 16px 32px 16px;
    }
  }
`

export default SaveButton
