import Popover from 'antd/es/popover'
import type { FC } from 'react'

import { fixedPostSearchCacheKey, usePostSearchMutation } from '@redux/features/checkout/api'

import Button from '@base/buttons/Button'
import useToggle from '@hooks/useToggle/useToggle'
import { UilAnalytics } from '@iconscout/react-unicons'
import ButtonSortMenu from '@widgets/checkout/timetable/routeData/trainsList/trainsListHeader/buttonsControl/ButtonSortMenu'

export const ButtonSort: FC = () => {
  const { on, setValue, toggleOff } = useToggle(false)
  const [, { data: timetableData }] = usePostSearchMutation({ fixedCacheKey: fixedPostSearchCacheKey })

  const onOpenChange = (open: boolean) => setValue(open)
  const onClose = () => toggleOff()

  const isDisabled = !Object.keys(timetableData?.trains || []).length

  return (
    <Popover
      arrow={false}
      content={<ButtonSortMenu onClose={onClose} />}
      onOpenChange={onOpenChange}
      open={on}
      overlayInnerStyle={{ background: 'none', boxShadow: 'none', padding: 0 }}
      placement="bottomLeft"
      trigger="click"
    >
      <Button disabled={isDisabled} type="link">
        <UilAnalytics />
      </Button>
    </Popover>
  )
}
